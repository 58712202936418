.gradientButton {
  /* padding: 10px 20px; */
  border: none;
  outline: none;
  color: white;
  background-size: 200% auto;
  background-image: linear-gradient(
    to right,
    #232526 0%,
    #4d4f52 51%,
    #232526 100%
  );
  transition: 0.5s;

  cursor: pointer;
}

.gradientButton:hover {
  background-position: right center; /* change the direction of the gradient on hover */
}

.btnGrad {
  background-image: linear-gradient(
    to right,

    #1c75bc 0%,
    #2f80ed 51%,

    #1c75bc 100%
  );

  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;

  display: block;
}

.btnGrad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
